export type Theme = {
  'theme-color-neutral': string;
  'theme-color-text': string;
  'theme-color-accent': string;
  'theme-color-emphasize': string;
  'theme-color-primary': string;
  'theme-color-important': string;
  'theme-color-secondary': string;
  'theme-color-tertiary': string;
  'font-heading': string;
  'font-body': string;
};

export const getThemeConfig = (theme?: Theme | null) =>
  theme ? (
    <style jsx global>{`
      :root {
        --theme-color-neutral: ${theme['theme-color-neutral']};
        --theme-color-accent: ${theme['theme-color-accent']};
        --theme-color-text: ${theme['theme-color-text']};
        --theme-color-emphasize: ${theme['theme-color-emphasize']};
        --theme-color-primary: ${theme['theme-color-primary']};
        --theme-color-important: ${theme['theme-color-important']};
        --theme-color-secondary: ${theme['theme-color-secondary']};
        --theme-color-tertiary: ${theme['theme-color-tertiary']};
        --theme-font-heading: ${theme['font-heading']};
        --theme-font-body: ${theme['font-body']};
      }
    `}</style>
  ) : null;
