import { getIsMultiLanguageDomain } from './getIsMultiLanguageDomain';
import { getDomainConfigByLocale } from './getDomainConfigByLocale';
import { getLocalizedLinkPath } from './getLocalizedLinkPath';
import { getMarket } from './getMarket';
import { getPathTranslations } from './getPathTranslations';
import { getDomainConfigByHost } from './getDomainConfigByHost';

export const LocalizationService = {
  getMarket,
  getLocalizedLinkPath,
  getDomainConfigByLocale,
  getIsMultiLanguageDomain,
  getPathTranslations,
  getDomainConfigByHost,
};
